import React from "react";
import { graphql } from "gatsby";

import styled from "styled-components";
import Layout from "../components/Layout";

import Seo from "../components/Seo";
import AuthorBox from "../components/AuthorBox";

import { BlogCard } from "../components/FeaturedPosts";
import { CasinoCard } from "../components/CasinosList";

const AuthorPage = ({ data }) => {
  const pageData = data?.contentfulAuthorProfile;
  const casinosData = data?.allContentfulCasino;
  const blogPostsData = data?.allContentfulBlogPost;

  return (
    <Layout>
      <Seo
        title={pageData?.seoComponent?.seoTitle}
        description={pageData?.seoComponent?.seoDescription}
        url={`https://nettikasinolista.com/kirjoittaja/${pageData?.seoComponent?.seoSlug}/`}
        canonical={`https://nettikasinolista.com/kirjoittaja/${pageData?.seoComponent?.seoSlug}/`}
      />
      <WrapperContent>
        <div>
          <AuthorBox
            authorName={pageData.name}
            image={pageData.avatar}
            withTitle={false}
            backstory={pageData.backstory.childMarkdownRemark.html}
          />
          <div>
            {blogPostsData.edges.length ? <Title>Kirjoittajan viimeisimmät artikkelit</Title> : null}
            <RowWrapper className="row casino-columns__list">
              {blogPostsData.edges.length
                ? blogPostsData.edges.map((cardItem) => {
                    return (
                      <BlogCard
                        key={cardItem?.node.title}
                        title={cardItem?.node.title}
                        slug={cardItem?.node.slug}
                        icon={cardItem?.node.icon}
                        cardImage={cardItem?.node.featuredImage}
                        categories={cardItem?.node.categories}
                        createdData={cardItem?.node.createdData}
                      />
                    );
                  })
                : null}
            </RowWrapper>
            {casinosData.edges.length ? <Title>Kirjoittajan uusimmat arvostelut</Title> : null}
            <CasinoCardsWrapper>
              <RowWrapper className="row casino-columns__list">
                {casinosData.edges.length
                  ? casinosData.edges.map((card) => {
                      return (
                        <CasinoCard
                          casinoName={card.node.casinoName}
                          key={card?.node.casinoName}
                          description={card?.node.shortDescription}
                          referralUrl={card?.node.referralUrl}
                          backgroundColor={card?.node.logoBackgroundColor}
                          logo={card?.node.logo}
                          slug={card?.node.slug}
                          categories={card?.node.categories}
                          cardSize={card}
                          rating={card?.node.rating}
                        />
                      );
                    })
                  : null}
              </RowWrapper>
            </CasinoCardsWrapper>
          </div>
        </div>
      </WrapperContent>
    </Layout>
  );
};

export default AuthorPage;

const WrapperContent = styled.div`
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 12px;
`;

const Title = styled.h2`
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;

  @media (max-width: 991.98px) {
    margin-top: 24px;
  }
`;

const RowWrapper = styled.div`
  margin: 0 auto 4rem;
`;

const CasinoCardsWrapper = styled.div`
  margin-top: 24px;
`;

export const query = graphql`
  query author($name: String!) {
    contentfulAuthorProfile(name: { eq: $name }) {
      seoComponent {
        seoTitle
        seoDescription
        seoSlug
      }
      avatar {
        url
        gatsbyImage(width: 120, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
        file {
          url
        }
      }
      name
      backstory {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulCasino(
      filter: { author: { name: { eq: $name } } }
      limit: 6
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          id
          casinoName
          shortDescription
          logoBackgroundColor
          slug
          categories
          referralUrl
          rating
          logo {
            gatsbyImage(width: 120, quality: 70, placeholder: NONE, formats: [AUTO, WEBP])
          }
        }
      }
    }
    allContentfulBlogPost(
      filter: { author: { name: { eq: $name } } }
      limit: 4
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          title
          createdData
          categories
          slug
          featuredImage {
            gatsbyImage(width: 300, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;
